export default {
  version: 8,
  name: "Seedzone Map",
  center: [-115.5, 54.53],
  zoom: 5.4,
  bearing: 0,
  pitch: 0,
  sources: {
    // seedzone: {
    //   url: "mapbox://opengeo.seedzone",
    //   type: "vector",
    // },
    // ats: {
    //   url: "mapbox://shaktichris.2ovozha6",
    //   type: "vector",
    // },
    // twp: {
    //   url: "mapbox://opengeo.twp",
    //   type: "vector",
    // },
    // sec: {
    //   url: "mapbox://opengeo.sec",
    //   type: "vector",
    // },
    // qtr: {
    //   url: "mapbox://opengeo.qtr",
    //   type: "vector",
    // },
    // lsd: {
    //   url: "mapbox://opengeo.lsd",
    //   type: "vector",
    // },
    seedzone: {
      tiles: [
        "https://map.shaktitrees.com/tileserver-php/tileserver.php/tileserver/seedzone/{z}/{x}/{y}.pbf",
      ],
      type: "vector",
      minzoom: 4,
      maxzoom: 15,
    },
    lsd: {
      tiles: [
        "https://map.shaktitrees.com/tileserver-php/tileserver.php/tileserver/lsd/{z}/{x}/{y}.pbf",
      ],
      type: "vector",
      minzoom: 10,
      maxzoom: 14,
    },
    "bing-satellite-tiles": {
      type: "raster",
      tiles: [
        "//t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?&it=A&shading=hill&og=130&n={z}",
        "//t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?&it=A&shading=hill&og=130&n={z}",
        "//t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?&it=A&shading=hill&og=130&n={z}",
        "//t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?&it=A&shading=hill&og=130&n={z}",
      ],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="https://www.microsoft.com/en-us/maps/product/print-rights">Microsoft</a>',
    },
    "esri-satellite-tiles": {
      type: "raster",
      tiles: [
        "//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      ],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="http://www.esri.com/">Esri</a>, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    },
    "mapbox://mapbox.satellite": {
      url: "mapbox://mapbox.satellite",
      type: "raster",
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>, &copy; <a href="https://www.digitalglobe.com/">DigitalGlobe</a>',
    },
    "google-satellite-tiles-satellite": {
      type: "raster",
      tiles: ["https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="https://www.google.com/intl/en-US_US/help/terms_maps.html">Google</a>',
    },
    "google-satellite-tiles-hybrid": {
      type: "raster",
      tiles: ["https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="https://www.google.com/intl/en-US_US/help/terms_maps.html">Google</a>',
    },
    "google-satellite-tiles-roads": {
      type: "raster",
      tiles: ["https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="https://www.google.com/intl/en-US_US/help/terms_maps.html">Google</a>',
    },
    // "mapnik-satellite-tiles": {
    //   "type": "raster",
    //   "tiles": [
    //     "http://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //     "http://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //     "http://c.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //   ],
    //   "tileSize": 256,
    //   "attribution": "Map data &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
    // },
    "esritopo-satellite-tiles": {
      type: "raster",
      tiles: [
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
      ],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="http://www.esri.com/">Esri</a>; DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
    },
    "esrinatgeo-satellite-tiles": {
      type: "raster",
      tiles: [
        "https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
      ],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="http://www.esri.com/">Esri</a>; National Geographic, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC',
    },
    "opentopomap-satellite-tiles": {
      type: "raster",
      tiles: [
        "https://a.tile.opentopomap.org/{z}/{x}/{y}.png",
        "https://b.tile.opentopomap.org/{z}/{x}/{y}.png",
        "https://c.tile.opentopomap.org/{z}/{x}/{y}.png",
      ],
      tileSize: 256,
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/copyright/">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
  },
  glyphs: "mapbox://fonts/hast/{fontstack}/{range}.pbf",
  sprite: "mapbox://sprites/mapbox/streets-v8",
  layers: [
    {
      id: "satellite-bing",
      type: "raster",
      source: "bing-satellite-tiles",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },
    {
      id: "satellite-esri",
      type: "raster",
      source: "esri-satellite-tiles",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },
    {
      id: "satellite-mapbox",
      type: "raster",
      source: "mapbox://mapbox.satellite",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },
    {
      id: "satellite-google-satellite",
      type: "raster",
      source: "google-satellite-tiles-satellite",
      interactive: true,
      layout: {
        visibility: "visible",
      },
      paint: {},
    },
    {
      id: "satellite-google-hybrid",
      type: "raster",
      source: "google-satellite-tiles-hybrid",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },
    {
      id: "satellite-google-roads",
      type: "raster",
      source: "google-satellite-tiles-roads",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },
    // {
    //   "id": "satellite-mapnik",
    //   "type": "raster",
    //   "source": "mapnik-satellite-tiles",
    //   "interactive": true,
    //   "layout": {
    //     "visibility": "none"
    //   },
    //   "paint": {}
    // },
    {
      id: "satellite-esritopo",
      type: "raster",
      source: "esritopo-satellite-tiles",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },
    {
      id: "satellite-esrinatgeo",
      type: "raster",
      source: "esrinatgeo-satellite-tiles",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },
    {
      id: "satellite-opentopomap",
      type: "raster",
      source: "opentopomap-satellite-tiles",
      interactive: true,
      layout: {
        visibility: "none",
      },
      paint: {},
    },

    // stream_1
    {
      id: "overlay-stream_1",
      source: "seedzone",
      "source-layer": "stream_1",
      type: "fill",
      layout: {
        visibility: "none",
      },
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": {
          base: 1,
          stops: [
            [0, 0],
            [5.5, 0.55],
            [9, 0.55],
            [10, 0.55],
          ],
        },
        "fill-outline-color": ["get", "color"],
      },
    },
    {
      id: "overlay-stream_1-line",
      source: "seedzone",
      "source-layer": "stream_1",
      type: "line",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 0.75],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 1],
            [14, 8],
          ],
        },
        "line-offset": {
          base: 1,
          stops: [
            [5, 0],
            [14, -2.5],
          ],
        },
      },
    },
    {
      id: "overlay-stream_1-outline",
      source: "seedzone",
      "source-layer": "stream_1",
      type: "line",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 1],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 0.75],
            [12, 1],
          ],
        },
      },
    },
    // stream_1_labels
    {
      id: "overlay-stream_1-labels-halo",
      type: "symbol",
      source: "seedzone",
      "source-layer": "stream_1_labels",
      layout: {
        "text-field": "{seedzone}",
        "text-font": {
          base: 1,
          stops: [
            [0, ["Open Sans Bold", "Arial Unicode MS Bold"]],
            [22, ["Open Sans Bold", "Arial Unicode MS Bold"]],
          ],
        },
        "text-size": {
          base: 1,
          stops: [
            [3, 14],
            [10, 18],
            [20, 35],
          ],
        },
        visibility: "none",
      },
      paint: {
        "text-color": "hsl(0, 0%, 100%)",
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 1,
        "text-halo-blur": 1,
        "text-opacity": 1,
      },
      minzoom: 5,
    },
    {
      id: "overlay-stream_1-labels",
      type: "symbol",
      source: "seedzone",
      "source-layer": "stream_1_labels",
      layout: {
        "text-field": "{seedzone}",
        "text-font": {
          base: 1,
          stops: [
            [0, ["Open Sans Bold", "Arial Unicode MS Bold"]],
            [22, ["Open Sans Bold", "Arial Unicode MS Bold"]],
          ],
        },
        "text-size": {
          base: 1,
          stops: [
            [3, 14],
            [10, 18],
            [20, 35],
          ],
        },
        visibility: "none",
      },
      paint: {
        "text-color": ["get", "color"],
        "text-opacity": 1,
      },
      minzoom: 5,
    },

    // stream_2
    {
      id: "overlay-stream_2-bs",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "fill",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "Black Spruce"]],
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": {
          base: 1,
          stops: [
            [0, 0],
            [5.5, 0.55],
            [9, 0.55],
            [10, 0.55],
          ],
        },
        "fill-outline-color": ["get", "color"],
      },
    },
    {
      id: "overlay-stream_2-bs-line",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "Black Spruce"]],
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 0.75],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 1],
            [14, 8],
          ],
        },
        "line-offset": {
          base: 1,
          stops: [
            [5, 0],
            [14, -2.5],
          ],
        },
      },
    },
    {
      id: "overlay-stream_2-bs-outline",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "Black Spruce"]],
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 1],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 0.75],
            [12, 1],
          ],
        },
      },
    },

    {
      id: "overlay-stream_2-lp",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "fill",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "Pine"]],
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": {
          base: 1,
          stops: [
            [0, 0],
            [5.5, 0.55],
            [9, 0.55],
            [10, 0.55],
          ],
        },
        "fill-outline-color": ["get", "color"],
      },
    },
    {
      id: "overlay-stream_2-lp-line",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "Pine"]],
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 0.75],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 1],
            [14, 8],
          ],
        },
        "line-offset": {
          base: 1,
          stops: [
            [5, 0],
            [14, -2.5],
          ],
        },
      },
    },
    {
      id: "overlay-stream_2-lp-outline",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "Pine"]],
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 1],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 0.75],
            [12, 1],
          ],
        },
      },
    },

    {
      id: "overlay-stream_2-ws",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "fill",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "White Spruce"]],
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": {
          base: 1,
          stops: [
            [0, 0],
            [5.5, 0.55],
            [9, 0.55],
            [10, 0.55],
          ],
        },
        "fill-outline-color": ["get", "color"],
      },
    },
    {
      id: "overlay-stream_2-ws-line",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "White Spruce"]],
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 0.75],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 1],
            [14, 8],
          ],
        },
        "line-offset": {
          base: 1,
          stops: [
            [5, 0],
            [14, -2.5],
          ],
        },
      },
    },
    {
      id: "overlay-stream_2-ws-outline",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["all", ["==", "nsr", "White Spruce"]],
      paint: {
        "line-color": ["get", "color"],
        "line-opacity": {
          base: 1,
          stops: [
            [5, 0],
            [10, 1],
          ],
        },
        "line-width": {
          base: 1,
          stops: [
            [5, 0.75],
            [12, 1],
          ],
        },
      },
    },

    // stream_1 selected
    {
      id: "overlay-selected-stream_1",
      source: "seedzone",
      "source-layer": "stream_1",
      type: "line",
      layout: {
        visibility: "visible",
      },
      paint: {
        "line-color": "#00FFFF",
        "line-width": 3,
      },
      filter: ["in", "fid", ""],
    },

    // stream_2 selected
    {
      id: "overlay-selected-stream_2",
      source: "seedzone",
      "source-layer": "stream_2",
      type: "line",
      layout: {
        visibility: "visible",
      },
      paint: {
        "line-color": "#00FFFF",
        "line-width": 3,
      },
      filter: ["in", "fid", ""],
    },

    // lsd
    {
      id: "overlay-ats-lsd",
      source: "lsd",
      "source-layer": "lsd",
      type: "line",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": "hsla(360%, 100%, 50%, 0.5)",
        "line-width": {
          base: 0.5,
          stops: [
            [12, 0.5],
            [16, 2],
          ],
        },
      },
      // minzoom: 14.5,
      minzoom: 12,
      maxzoom: 22,
    },
    {
      id: "overlay-ats-lsd-fill",
      source: "lsd",
      "source-layer": "lsd",
      type: "fill",
      layout: {
        visibility: "visible",
      },
      paint: {
        "fill-outline-color": "hsla(0%, 0%, 0%, 0.01)",
        "fill-color": "hsla(0%, 0%, 0%, 0.01)",
        "fill-opacity": 0.01,
      },
      minzoom: 12,
      maxzoom: 22,
    },
    // lsd label
    {
      id: "overlay-ats-lsd-centroid-label-halo",
      type: "symbol",
      source: "lsd",
      "source-layer": "lsd_centroid",
      layout: {
        "text-field": "{LS}-{SEC}-{TWP}-{RGE} W{M}",
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-size": {
          base: 1,
          stops: [
            [3, 8],
            [10, 10],
            [20, 35],
          ],
        },
        visibility: "none",
      },
      paint: {
        "text-color": "hsl(0, 0%, 100%)",
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 1,
        "text-halo-blur": 1,
        "text-opacity": 1,
      },
      // minzoom: 14.5,
      minzoom: 14,
      maxzoom: 22,
    },
    {
      id: "overlay-ats-lsd-centroid-label",
      type: "symbol",
      source: "lsd",
      "source-layer": "lsd_centroid",
      layout: {
        "text-field": "{LS}-{SEC}-{TWP}-{RGE} W{M}",
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-size": {
          base: 1,
          stops: [
            [3, 8],
            [10, 10],
            [20, 35],
          ],
        },
        visibility: "none",
      },
      paint: {
        "text-color": "hsl(0, 100%, 0%)",
        "text-opacity": 1,
      },
      // minzoom: 14.5,
      minzoom: 14,
      maxzoom: 22,
    },

    // // qtr
    // {
    //   id: "overlay-ats-qtr",
    //   source: "qtr",
    //   "source-layer": "qtr",
    //   type: "line",
    //   layout: {
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "line-color": "hsla(100%, 100%, 30%, 0.5)",
    //     "line-width": 2,
    //   },
    //   minzoom: 13.5,
    //   maxzoom: 14.5,
    // },
    // {
    //   id: "overlay-ats-qtr-fill",
    //   source: "qtr",
    //   "source-layer": "qtr",
    //   type: "fill",
    //   layout: {
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "fill-outline-color": "hsla(0%, 0%, 0%, 0.01)",
    //     "fill-color": "hsla(0%, 0%, 0%, 0.01)",
    //     "fill-opacity": 0.01,
    //   },
    //   minzoom: 13.5,
    //   maxzoom: 14.5,
    // },
    // // qtr label
    // {
    //   id: "overlay-ats-qtr-centroid-label-halo",
    //   type: "symbol",
    //   source: "qtr",
    //   "source-layer": "qtr_centroid",
    //   layout: {
    //     "text-field": "{SEC}-{TWP}-{RGE}W{M}",
    //     "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
    //     "text-size": {
    //       base: 1,
    //       stops: [
    //         [3, 8],
    //         [10, 10],
    //         [20, 25],
    //       ],
    //     },
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "text-color": "hsl(0, 0%, 100%)",
    //     "text-halo-color": "hsl(0, 0%, 100%)",
    //     "text-halo-width": 1,
    //     "text-halo-blur": 1,
    //     "text-opacity": 1,
    //   },
    //   minzoom: 13.5,
    //   maxzoom: 14.5,
    // },
    // {
    //   id: "overlay-ats-qtr-centroid-label",
    //   type: "symbol",
    //   source: "qtr",
    //   "source-layer": "qtr_centroid",
    //   layout: {
    //     "text-field": "{SEC}-{TWP}-{RGE}W{M}",
    //     "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
    //     "text-size": {
    //       base: 1,
    //       stops: [
    //         [3, 8],
    //         [10, 10],
    //         [20, 25],
    //       ],
    //     },
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "text-color": "hsl(0, 100%, 0%)",
    //     "text-opacity": 1,
    //   },
    //   minzoom: 13.5,
    //   maxzoom: 14.5,
    // },

    // // sec
    // {
    //   id: "overlay-ats-sec",
    //   source: "sec",
    //   "source-layer": "sec",
    //   type: "line",
    //   layout: {
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "line-color": "hsla(360%, 100%, 50%, 0.5)",
    //     "line-width": 2,
    //   },
    //   minzoom: 11.5,
    //   maxzoom: 13.5,
    // },
    // {
    //   id: "overlay-ats-sec-fill",
    //   source: "sec",
    //   "source-layer": "sec",
    //   type: "fill",
    //   layout: {
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "fill-outline-color": "hsla(0%, 0%, 0%, 0.01)",
    //     "fill-color": "hsla(0%, 0%, 0%, 0.01)",
    //     "fill-opacity": 0.01,
    //   },
    //   minzoom: 11.5,
    //   maxzoom: 13.5,
    // },
    // // sec label
    // {
    //   id: "overlay-ats-sec-centroid-label-halo",
    //   type: "symbol",
    //   source: "sec",
    //   "source-layer": "sec_centroid",
    //   layout: {
    //     "text-field": "{SEC}-{TWP}-{RGE}W{M}",
    //     "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
    //     "text-size": {
    //       base: 1,
    //       stops: [
    //         [3, 8],
    //         [10, 10],
    //         [20, 25],
    //       ],
    //     },
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "text-color": "hsl(0, 0%, 100%)",
    //     "text-halo-color": "hsl(0, 0%, 100%)",
    //     "text-halo-width": 1,
    //     "text-halo-blur": 1,
    //     "text-opacity": 1,
    //   },
    //   minzoom: 11.5,
    //   maxzoom: 13.5,
    // },
    // {
    //   id: "overlay-ats-sec-centroid-label",
    //   type: "symbol",
    //   source: "sec",
    //   "source-layer": "sec_centroid",
    //   layout: {
    //     "text-field": "{SEC}-{TWP}-{RGE}W{M}",
    //     "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
    //     "text-size": {
    //       base: 1,
    //       stops: [
    //         [3, 8],
    //         [10, 10],
    //         [20, 25],
    //       ],
    //     },
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "text-color": "hsl(0, 100%, 0%)",
    //     "text-opacity": 1,
    //   },
    //   minzoom: 11.5,
    //   maxzoom: 13.5,
    // },

    // // twp
    // {
    //   id: "overlay-ats-twp",
    //   source: "twp",
    //   "source-layer": "twp",
    //   type: "line",
    //   layout: {
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "line-color": "hsla(0%, 0%, 0%, 0.5)",
    //     "line-width": 0.7,
    //   },
    //   minzoom: 6,
    //   maxzoom: 12,
    //   // maxzoom: 11.5,
    // },
    // {
    //   id: "overlay-ats-twp-fill",
    //   source: "twp",
    //   "source-layer": "twp",
    //   type: "fill",
    //   layout: {
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "fill-outline-color": "hsla(0%, 0%, 0%, 0.01)",
    //     "fill-color": "hsla(0%, 0%, 0%, 0.01)",
    //     "fill-opacity": 0.01,
    //   },
    //   minzoom: 6,
    //   maxzoom: 12,
    //   // maxzoom: 11.5,
    // },
    // // twp label
    // {
    //   id: "overlay-twp-lsd-centroid-label-halo",
    //   type: "symbol",
    //   source: "twp",
    //   "source-layer": "twp_centroid",
    //   layout: {
    //     "text-field": "{TWP}-{RGE}W{M}",
    //     "text-allow-overlap": true,
    //     "text-ignore-placement": true,
    //     "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
    //     "text-size": 16,
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "text-color": "hsl(0, 0%, 100%)",
    //     "text-halo-color": "hsl(0, 0%, 100%)",
    //     "text-halo-width": 1,
    //     "text-halo-blur": 1,
    //     "text-opacity": 1,
    //   },
    //   minzoom: 9.25,
    //   maxzoom: 11.5,
    // },
    // {
    //   id: "overlay-ats-twp-centroid-label",
    //   type: "symbol",
    //   source: "twp",
    //   "source-layer": "twp_centroid",
    //   layout: {
    //     "text-field": "{TWP}-{RGE}W{M}",
    //     "text-allow-overlap": true,
    //     "text-ignore-placement": true,
    //     "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
    //     "text-size": 16,
    //     visibility: "visible",
    //   },
    //   paint: {
    //     "text-color": "hsl(0, 100%, 0%)",
    //     "text-opacity": 1,
    //   },
    //   minzoom: 9.25,
    //   maxzoom: 11.5,
    // },
  ],
};
