<template>
  <div class="map-content">
    <UserGuide />

    <v-tour
      name="desktopTour"
      :steps="desktopTourSteps"
      :callbacks="desktopTourCallbacks"
    ></v-tour>

    <v-snackbar v-model="showSnackbar" color="orange">
      {{ snaskbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="showSnackbar = false">{{
          mdiClose
        }}</v-icon>
      </template>
    </v-snackbar>
    <div class="logo" @click="openSite">
      <img src="../assets/shakti-logo.png" alt />
    </div>

    <v-navigation-drawer
      v-if="searchSidebar"
      v-model="searchSidebar"
      fixed
      permanent
      right
      hide-overlay
      disable-resize-watcher
      width="305"
    >
      <v-row class="fill-width">
        <vuetify-google-autocomplete
          id="gac"
          class="ml-5"
          country="CA"
          append-icon
          placeholder="Address geocoding"
          v-on:placechanged="getGoogleSearchAddress"
          v-on:no-results-found="noAddressFound"
          types="geocode"
        ></vuetify-google-autocomplete>
        <v-btn
          v-if="isMobile"
          fab
          class="mt-4 mr-4"
          x-small
          @click.stop="searchSidebar = !searchSidebar"
        >
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-row>

      <v-divider id="v-step-0"></v-divider>

      <v-tooltip bottom max-width="280">
        <template v-slot:[`activator`]="{ on }">
          <v-textarea
            v-model="searchCoords"
            class="px-1 pt-2 pb-2"
            label="Coordinates (lat, lon)"
            hide-details
            v-on="on"
            rows="4"
            outlined
          ></v-textarea>
        </template>
        <span>
          <p>Search by coordinates, e.g.:</p>
          <ul>
            <li>54.50321, -115.49154</li>
            <li>54.60503 -115.26481</li>
          </ul>
          <p>
            To convert multiple coordinates, enter them on separate lines (use
            coma or space as delimiter between lat/lon coordinates). Then click
            "Search by coordinates" button
          </p>
        </span>
      </v-tooltip>
      <v-btn
        id="v-step-0-1"
        outlined
        width="290"
        class="mx-2 mb-2 search-btn"
        :loading="loadingCoords"
        :disabled="loadingCoords || searchCoords.length == 0"
        @click="requestCoords"
        >Search by coordinates (lat, lon)
      </v-btn>

      <v-divider id="v-step-0-1"></v-divider>

      <v-tooltip bottom max-width="280">
        <template v-slot:[`activator`]="{ on }">
          <v-textarea
            v-model="searchAts"
            class="px-1 pt-2 pb-2"
            label="ATS (example: 02-32-068-12 W6)"
            hide-details
            v-on="on"
            rows="4"
            outlined
          ></v-textarea>
        </template>
        <span>
          <p>Convert and map one or more ATS's, e.g.:</p>
          <ul>
            <li>02-32-068-12 W6</li>
            <li>02-32-068-12 W6M</li>
            <li>2-32-68-12 W6</li>
            <li>02-32-68-12W6M</li>
            <li>02-32-68-012 W6</li>
          </ul>
          <p>
            To convert multiple ATS's, enter them on separate lines. Then click
            "Search by Alberta Township - Range" button
          </p>
        </span>
      </v-tooltip>
      <v-btn
        id="v-step-1"
        outlined
        width="290"
        class="mx-2 mb-2 search-btn"
        :loading="loadingAts"
        :disabled="loadingAts || searchAts.length == 0"
        @click="requestAts"
        >Search by Alberta Township - Range
      </v-btn>

      <v-divider></v-divider>

      <download-csv
        v-if="atsSearchResultsCSV.length > 0"
        :data="atsSearchResultsCSV"
        :name="atsSearchResultsName"
        delimiter=","
      >
        <v-btn outlined width="290" class="mx-2 mb-2 mt-2">Download CSV</v-btn>
      </download-csv>

      <v-divider></v-divider>

      <v-expansion-panels v-model="panel" accordion multiple value="[0,1]">
        <v-expansion-panel v-if="atsSearchResults.length > 0">
          <v-expansion-panel-header class="pt-0 pb-0"
            >Search results</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item v-for="(item, i) in atsSearchResults" :key="i">
                  <v-list-item-content @click="zoomToItem(item)">
                    <v-list-item-title
                      v-text="
                        item.properties.ats
                          ? item.properties.ats
                          : item.properties.ats_search
                      "
                    ></v-list-item-title>
                    <template v-if="item.properties.ats">
                      <v-list-item-subtitle
                        >Seedzone, Deployment Region:</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Wild seed:
                        {{
                          item.properties.s1_seedzone | deleteDuplicates
                        }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Orchard seed:
                        {{
                          item.properties.s2_seedzone | deleteDuplicates
                        }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Elevation:
                        {{ item.properties.elevation }} m</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >GPS: {{ item.geometry.coordinates[1].toFixed(5) }}&deg;
                        {{
                          item.geometry.coordinates[0].toFixed(5)
                        }}&deg;</v-list-item-subtitle
                      >
                    </template>
                    <v-list-item-subtitle v-else class="red lighten-4"
                      >Not found</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  {{ i + 1 }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="pt-0 pb-0"
            >Seed zone layers</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-treeview
              v-model="activeOverlayLayers"
              dense
              selectable
              open-all
              return-object
              transition
              :items="overlayLayerItems"
            >
              <span
                slot="label"
                slot-scope="data"
                :id="`v-step-layer-${data.item.id}`"
                >{{ data.item.name }}</span
              >
            </v-treeview>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="pt-0 pb-0"
            >Township Range Layer</v-expansion-panel-header
          >
          <v-expansion-panel-content class="mb-0">
            <v-checkbox
              id="v-step-3"
              class="mt-0"
              v-model="townshipRangeLayer"
              label="ATS Grid"
              hide-details
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="pt-0 pb-0"
            >Base layers</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-radio-group
              v-model="activeBaseLayer"
              :mandatory="false"
              class="ml-3 mt-0"
              hide-details
            >
              <v-radio
                id="v-step-4"
                label="Bing Aerial Imagery"
                value="satellite-bing"
              ></v-radio>
              <v-radio
                label="ESRI World Imagery"
                value="satellite-esri"
              ></v-radio>
              <v-radio
                label="Mapbox Satellite"
                value="satellite-mapbox"
              ></v-radio>
              <v-radio
                label="Google Satellite"
                value="satellite-google-satellite"
              ></v-radio>
              <v-radio
                label="Google Hybrid"
                value="satellite-google-hybrid"
              ></v-radio>
              <v-radio
                label="Google Roads"
                value="satellite-google-roads"
              ></v-radio>
              <!-- <v-radio label="OpenStreetMap Mapnik" value="satellite-mapnik"></v-radio> -->
              <v-radio
                label="ESRI World Topo Map"
                value="satellite-esritopo"
              ></v-radio>
              <v-radio
                label="ESRI National Geographic"
                value="satellite-esrinatgeo"
              ></v-radio>
              <v-radio
                label="OpenTopoMap"
                value="satellite-opentopomap"
              ></v-radio>
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider></v-divider>

      <v-btn outlined class="ma-2" @click="resetUserMapConfig()"
        >Reset user map config
      </v-btn>
    </v-navigation-drawer>

    <v-main class="map-content">
      <v-container fluid>
        <div id="map"></div>
        <v-btn
          v-if="isMobile"
          fab
          small
          absolute
          right
          @click.stop="searchSidebar = !searchSidebar"
        >
          <v-icon>{{ mdiViewList }}</v-icon>
        </v-btn>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";
import extent from "turf-extent";

import mapStyle from "@/map-style.js";

import { mdiClose, mdiViewList } from "@mdi/js";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import centerOfMass from "@turf/center-of-mass";
import length from "@turf/length";
import area from "@turf/area";

import UserGuide from "@/components/UserGuide";

export default {
  metaInfo: {
    title: "Shakti Tree Map",
  },
  data() {
    return {
      isMobile: false,

      desktopTourSteps: [
        {
          target: "#v-step-0",
          content: `Search on the map by address`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#v-step-0-1",
          content: `Search on the map by coordinates`,
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: "#v-step-1",
          content: `Search on the map by Alberta Township Range`,
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: "#v-step-layer-1",
          content: `Click to change overlay seedzone layer visibility`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#v-step-3",
          content: `Click to change overlay Township Range layer visibility`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#v-step-4",
          content: `Click to change map base layer`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: ".mapbox-gl-draw_line",
          content: `Click to measure distances on the map`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
      ],

      desktopTourCallbacks: {
        onSkip: this.finishDesktopTour,
        onFinish: this.finishDesktopTour,
      },

      showSnackbar: false,
      snaskbarMessage: "",

      panel: [0, 1, 2, 3],

      activeOverlayLayers: [],
      overlayLayerItems: [
        {
          id: 1,
          name: "Stream 1",
          layer: "overlay-stream_1",
        },
        {
          id: 2,
          name: "Breeding region",
          children: [
            { id: 21, name: "Black Spruce", layer: "overlay-stream_2-bs" },
            { id: 23, name: "Pine", layer: "overlay-stream_2-lp" },
            { id: 24, name: "White Spruce", layer: "overlay-stream_2-ws" },
          ],
        },
      ],

      townshipRangeLayer: false,

      // mdiMagnify: mdiMagnify,
      mdiViewList: mdiViewList,
      mdiClose: mdiClose,

      searchAts: "",
      searchCoords: "",

      searchSidebar: true,
      searchAddress: {
        lat: null,
        lon: null,
        address: null,
      },

      atsSearchResults: [],
      atsSearchResultsCSV: [],

      loadingAts: false,
      loadingCoords: false,

      activeBaseLayer: "satellite-google-satellite",

      map: {},
      mapStyle: mapStyle,

      clickElevation: "",
      clickAts: "",
      clickS1nsr: "",
      clickS1seedzone: "",
      clickS2nsr: "",
      clickS2seedzone: "",
    };
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  components: {
    UserGuide,
  },

  filters: {
    deleteDuplicates: (value) => {
      if (!value) return "";
      let arr = [...new Set(value.split(", "))];
      return arr.join(", ");
    },
  },

  computed: {
    atsSearchResultsName() {
      return `ats_search_results_${new Date().toISOString().slice(0, 10)}.csv`;
    },
  },

  watch: {
    activeOverlayLayers() {
      this.mapStyle.layers.forEach((layer) => {
        const layerId = layer.id;

        if (
          layerId.includes("overlay-stream_1") ||
          layerId.includes("overlay-stream_2")
        ) {
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }

        this.activeOverlayLayers.forEach((item) => {
          const layerName = item.layer;

          if (
            (layerId.includes("overlay-stream_1") ||
              layerId.includes("overlay-stream_2")) &&
            layerId.includes(layerName)
          ) {
            this.map.setLayoutProperty(layerId, "visibility", "visible");
          }
        });
      });

      // save activeOverlayLayers
      this.$localStorage.set(
        "activeOverlayLayers",
        JSON.stringify(this.activeOverlayLayers)
      );
    },

    townshipRangeLayer() {
      this.mapStyle.layers.forEach((layer) => {
        const layerId = layer.id;

        if (this.townshipRangeLayer && layerId === "overlay-ats-lsd") {
          this.map.setLayoutProperty(layerId, "visibility", "visible");
        }

        if (!this.townshipRangeLayer && layerId === "overlay-ats-lsd") {
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }

        if (
          this.townshipRangeLayer &&
          layerId === "overlay-ats-lsd-centroid-label-halo"
        ) {
          this.map.setLayoutProperty(layerId, "visibility", "visible");
        }

        if (
          !this.townshipRangeLayer &&
          layerId === "overlay-ats-lsd-centroid-label-halo"
        ) {
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }

        if (
          this.townshipRangeLayer &&
          layerId === "overlay-ats-lsd-centroid-label"
        ) {
          this.map.setLayoutProperty(layerId, "visibility", "visible");
        }

        if (
          !this.townshipRangeLayer &&
          layerId === "overlay-ats-lsd-centroid-label"
        ) {
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }
      });

      // save townshipRangeLayer
      this.$localStorage.set("townshipRangeLayer", this.townshipRangeLayer);
    },

    activeBaseLayer() {
      this.mapStyle.layers.forEach((layer) => {
        const layerId = layer.id;

        if (this.activeBaseLayer == layerId && !layerId.includes("overlay")) {
          this.map.setLayoutProperty(layerId, "visibility", "visible");

          // save activeBaseLayer
          this.$localStorage.set("activeBaseLayer", this.activeBaseLayer);
        }
        if (this.activeBaseLayer != layerId && !layerId.includes("overlay")) {
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }
      });
    },
  },

  methods: {
    onResize() {
      if (window.innerWidth < 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.searchSidebar = true;
      }
    },

    finishDesktopTour() {
      this.$localStorage.set("desktopTour", false);
    },

    resetUserMapConfig() {
      this.$localStorage.remove("desktopTour");
      this.$tours["desktopTour"].start();
      this.activeBaseLayer = "satellite-google-satellite";
      this.activeOverlayLayers = [
        // { id: 1, name: "Stream 1", layer: "overlay-stream_1" },
      ];
      this.townshipRangeLayer = false;
    },

    openSite() {
      this.$ga.event({
        eventCategory: "open shaktireforestation.com",
        eventAction: "open shaktireforestation.com",
      });

      window.open("https://shaktireforestation.com/");
    },

    getGoogleSearchAddress(addressData) {
      if (addressData && addressData.latitude && addressData.longitude) {
        this.searchAddress.lat = addressData.latitude.toFixed(7);
        this.searchAddress.lon = addressData.longitude.toFixed(7);

        this.map.flyTo({
          center: [this.searchAddress.lon, this.searchAddress.lat],
        });

        this.$ga.event({
          eventCategory: "search address",
          eventAction: "search address",
        });
      }
    },

    noAddressFound(addressData) {
      let coord_string = addressData.name;
      if (coord_string.length > 0) {
        let coord = [];
        if (coord_string.indexOf(",") > -1) {
          coord = coord_string.trim().replace(/\s/g, " ").split(",");
        } else {
          coord = coord_string.trim().replace(/\s\s+/g, " ").split(" ");
        }

        if (coord.length === 2) {
          const lat = coord[0];
          const lon = coord[1];

          this.map.flyTo({
            center: [lon, lat],
          });

          let popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: false,
          });
          let popupTextAts = "";
          this.getElevation(lon, lat).then(() => {
            popupTextAts = `<b>ATS:</b> ${this.clickAts}<br><b>Elevation:</b> ${this.clickElevation} m<br><b>GPS:</b> ${lat}&deg;${lon}&deg;`;

            this.copyToClipboard(this.clickAts);

            if (popupTextAts != "") {
              popup
                .setLngLat({ lng: lon, lat: lat })
                .setHTML(popupTextAts)
                .addTo(this.map);
            } else {
              popup.remove();
            }
          });
        } else {
          this.showSnackbar = true;
          this.snaskbarMessage = "Not found";
        }
      }
      // 56.40900, -117.31256
      // 54.192025,-115.24826
      // 54.192025 -115.24826
    },

    zoomToItem(item) {
      if (item.properties.ats) {
        let bbox = extent(item);
        this.map.fitBounds(bbox, { maxZoom: 15 });
      }
    },

    resultsToJson() {
      this.atsSearchResultsCSV = [];
      this.atsSearchResults.forEach((item, index) => {
        this.atsSearchResultsCSV.push({
          id: index + 1,
          ats_search: item.properties.ats_search,
          ats: item.properties.ats,
          s1_nsr: item.properties.s1_nsr,
          s1_seedzone: item.properties.s1_seedzone,
          s2_nsr: item.properties.s2_nsr,
          s2_seedzone: item.properties.s2_seedzone,
          gps: `${item.geometry.coordinates[1].toFixed(
            5
          )} ${item.geometry.coordinates[0].toFixed(5)}`,
          lat: item.geometry.coordinates[1].toFixed(5),
          lon: item.geometry.coordinates[0].toFixed(5),
          elevation: item.properties.elevation,
        });
      });
    },

    async getElevation(lon, lat) {
      const formData = {
        lon: lon,
        lat: lat,
      };

      this.clickElevation = "";
      this.clickAts = "";
      this.clickS1nsr = "";
      this.clickS1seedzone = "";
      this.clickS2nsr = "";
      this.clickS2seedzone = "";

      try {
        await axios({
          url: `${process.env.VUE_APP_API_HOST_COORDS}`,
          method: "post",
          data: formData,
        })
          .then(
            (response) => {
              this.clickElevation =
                response.data && response.data.elevation
                  ? response.data.elevation
                  : "";
              this.clickAts =
                response.data && response.data.ats ? response.data.ats : "";

              this.clickS1nsr =
                response.data && response.data.s1_nsr
                  ? response.data.s1_nsr
                  : "";
              this.clickS1seedzone =
                response.data && response.data.s1_seedzone
                  ? response.data.s1_seedzone
                  : "";
              this.clickS2nsr =
                response.data && response.data.s2_nsr
                  ? response.data.s2_nsr
                  : "";
              this.clickS2seedzone =
                response.data && response.data.s2_seedzone
                  ? response.data.s2_seedzone
                  : "";
            },
            (error) => {
              console.log("error", error);
              throw error;
            }
          )
          .catch((error) => {
            console.log("cerror", error);
            throw error;
          });
      } catch (e) {
        console.error(e);
      }
    },

    getAts(ats) {
      this.loadingAts = true;

      const formData = {
        ats: ats,
      };

      axios({
        url: `${process.env.VUE_APP_API_HOST_ATS}`,
        method: "post",
        data: formData,
      })
        .then(
          (response) => {
            let data = response.data;
            let geojson_data = {
              type: "FeatureCollection",
              features: [],
            };

            data.forEach((item) => {
              geojson_data.features.push({
                type: "Feature",
                properties: {
                  ats_search: item.ats_search,
                  ats_normalized: item.ats_normalized,
                  lon: item.lon,
                  lat: item.lat,
                  ats: item.ats,
                  s1_seedzone: item.s1_seedzone,
                  s1_nsr: item.s1_nsr,
                  s2_seedzone: item.s2_seedzone,
                  s2_nsr: item.s2_nsr,
                  elevation: item.elevation,
                },
                geometry: {
                  type: "Point",
                  coordinates: [item.lon, item.lat],
                },
              });
            });

            this.atsSearchResults = geojson_data.features;

            let geojson_data_valid = geojson_data;
            geojson_data_valid.features = geojson_data_valid.features.filter(
              (item) => {
                return (
                  item.geometry.coordinates[0] != 0 &&
                  item.geometry.coordinates[1] != 0
                );
              }
            );

            this.map.getSource("point-source").setData(geojson_data_valid);
            let bbox = extent(geojson_data_valid);
            this.map.fitBounds(bbox, {
              maxZoom: 15,
              padding: { top: 10, bottom: 10, left: 100, right: 450 },
            });

            this.resultsToJson();

            this.loadingAts = false;
          },
          (error) => {
            console.log("error", error);
            this.loadingAts = false;
            throw error;
          }
        )
        .catch((error) => {
          console.log("cerror", error);
          this.loadingAts = false;
          throw error;
        });
    },

    requestCoords() {
      this.searchAts = "";
      this.atsSearchResults = [];
      this.loadingCoords = true;

      let coords = this.searchCoords.split("\n").map((x) => {
        return x
          .trim()
          .replaceAll(",", "")
          .replaceAll("°", "")
          .replace(/\s\s+/g, "")
          .split("-")
          .map((el, idx) => {
            return idx === 0 ? parseFloat(el) : -parseFloat(el);
          });
      });

      let ats_string = "";

      coords = coords.filter((item) => {
        const lat = item[0];
        const lon = item[1];
        return parseFloat(lat) == lat && parseFloat(lon) == lon;
      });

      if (coords.length > 0) {
        coords.forEach((item) => {
          const lat = item[0];
          const lon = item[1];

          this.getElevation(lon, lat).then(() => {
            const ats = this.clickAts;
            const elevation = this.clickElevation;

            const s1_nsr = this.clickS1nsr;
            const s1_seedzone = this.clickS1seedzone;
            const s2_nsr = this.clickS2nsr;
            const s2_seedzone = this.clickS2seedzone;

            ats_string = ats_string + "," + ats;

            const point_item = {
              type: "Feature",
              geometry: {
                coordinates: [lon, lat],
                type: "Point",
              },
              properties: {
                ats: ats,
                elevation: elevation,
                s1_nsr: s1_nsr,
                s1_seedzone: s1_seedzone,
                s2_nsr: s2_nsr,
                s2_seedzone: s2_seedzone,
              },
            };

            this.atsSearchResults.push(point_item);
          });
        });

        setTimeout(() => {
          this.resultsToJson();

          let geojson = {
            type: "FeatureCollection",
            features: this.atsSearchResults,
          };

          this.map.getSource("point-source").setData(geojson);
          let bbox = extent(geojson);
          this.map.fitBounds(bbox, {
            maxZoom: 15,
            padding: { top: 10, bottom: 10, left: 100, right: 450 },
          });

          this.$router
            .push({
              path: "/",
              query: {
                query: `${ats_string}`,
              },
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });

          this.loadingCoords = false;
        }, 2500);
      } else {
        this.showSnackbar = true;
        this.snaskbarMessage = "Search items is invalid";
        this.loadingCoords = false;
      }
    },

    requestAts() {
      this.loadingAts = true;

      let ats = this.searchAts
        .split("\n")
        .map((item) => {
          return item.replace(",", "");
        })
        .filter((item) => {
          return item != "";
        })
        .join(",");

      this.$ga.event({
        eventCategory: "search ats",
        eventAction: "search ats",
        eventLabel: ats,
      });

      if (ats) {
        this.getAts(ats);

        this.$router
          .push({
            path: "/",
            query: {
              query: `${ats}`,
            },
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      } else {
        this.showSnackbar = true;
        this.snaskbarMessage = "Search items is invalid";
        this.loadingAts = false;
      }
    },

    initDrawControl(map) {
      let draw = new MapboxDraw({
        keybindings: false,
        displayControlsDefault: false,
        controls: {
          line_string: true,
          polygon: true,
          trash: true,
        },
      });
      map.addControl(draw, "bottom-right");

      map.on("draw.create", updateMeasurements);
      map.on("draw.delete", updateMeasurements);
      map.on("draw.update", updateMeasurements);

      let button_line = document.getElementsByClassName(
        "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line"
      )[0];
      button_line.title = "Measure distance";
      let button_area = document.getElementsByClassName(
        "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
      )[0];
      button_area.title = "Measure area";

      function updateMeasurements() {
        let geojson = draw.getAll();

        geojson.features = geojson.features.map((item) => {
          let line_length = length(item); // meters
          let polygon_area = area(item); // sq meters
          item.properties.length = `${line_length.toFixed(2)} km`;

          if (polygon_area > 0) {
            polygon_area = polygon_area / 10000; // ha
            item.properties.area = `${polygon_area.toFixed(2)} ha`;
          }
          return item;
        });

        let point_geojson = {
          type: "FeatureCollection",
          features: [],
        };

        point_geojson.features = geojson.features.filter((item) => {
          if (item.properties.area) {
            let point_feature = centerOfMass(item);
            point_feature.properties.length = item.properties.length;
            point_feature.properties.area = item.properties.area;
            return point_feature;
          }
        });

        if (map.getLayer("line_length")) {
          map.removeLayer("line_length");
        }
        if (map.getLayer("symbols")) {
          map.removeLayer("symbols");
        }
        if (map.getSource("line_length")) {
          map.removeSource("line_length");
        }

        map.addSource("line_length", {
          type: "geojson",
          data: geojson,
        });

        map.addLayer({
          id: "line_length",
          type: "line",
          source: "line_length",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "red",
            "line-width": 0,
          },
        });

        map.addLayer({
          id: "symbols",
          type: "symbol",
          source: "line_length",
          paint: {
            "text-color": "hsl(0, 0%, 100%)",
            "text-halo-color": "hsl(0, 5%, 0%)",
            "text-halo-width": 1,
            "text-halo-blur": 1,
          },
          layout: {
            "symbol-placement": "line",
            "text-font": ["Open Sans Regular"],
            "text-field": "{length}",
            "text-size": 18,
            "text-justify": "center",
            "text-anchor": "center",
            "symbol-avoid-edges": false,
            "text-ignore-placement": true,
            "text-allow-overlap": true,
            "text-rotation-alignment": "map",
          },
        });

        if (map.getLayer("symbols_area")) {
          map.removeLayer("symbols_area");
        }

        if (map.getSource("polygon_area")) {
          map.removeSource("polygon_area");
        }

        map.addSource("polygon_area", {
          type: "geojson",
          data: point_geojson,
        });

        map.addLayer({
          id: "symbols_area",
          type: "symbol",
          source: "polygon_area",
          paint: {
            "text-color": "hsl(0, 0%, 100%)",
            "text-halo-color": "hsl(0, 5%, 0%)",
            "text-halo-width": 1,
            "text-halo-blur": 1,
          },
          layout: {
            "text-font": ["Open Sans Regular"],
            "text-field": "{area}",
            "text-size": 18,
            "text-justify": "center",
            "text-anchor": "center",
            "symbol-avoid-edges": false,
            "text-ignore-placement": true,
            "text-allow-overlap": true,
            "text-rotation-alignment": "map",
          },
        });
      }
    },

    copyToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

    const map = new mapboxgl.Map({
      container: "map",
      style: this.mapStyle,
      center: [-113.884, 55.241],
      zoom: 6.5,
      minZoom: 5,
      maxZoom: 18,
      hash: true,
    });
    this.map = map;

    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();

    map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      }),
      "bottom-right"
    );

    map.on("load", () => {
      this.map.addSource("attribution", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        attribution:
          '<a href="https://shaktireforestation.com/">Shakti 2021 All Rights Reserved.</a>',
      });

      this.map.addLayer({
        id: "attribution-layer",
        type: "symbol",
        source: "attribution",
      });

      this.map.addSource("point-source", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
        },
      });

      this.map.addLayer({
        id: "point-layer",
        type: "symbol",
        source: "point-source",
        layout: {
          "icon-image": "marker-15",
          "text-field": "{ats}",
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 0.6],
          "text-anchor": "top",
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
        },
        paint: {
          "text-halo-color": "#FFFFFF",
          "text-halo-width": 1.5,
        },
        minzoom: 0,
        maxzoom: 22,
      });

      let popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: false,
      });

      this.map.on("click", (e) => {
        let popupText1 = "";
        let popupText2 = "";
        let popupTextAts = "";

        let features_s1 = this.map.queryRenderedFeatures(
          [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5],
          ],
          {
            layers: ["overlay-stream_1"],
          }
        );

        let features_s2 = this.map.queryRenderedFeatures(
          [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5],
          ],
          {
            layers: [
              "overlay-stream_2-bs",
              "overlay-stream_2-lp",
              "overlay-stream_2-ws",
            ],
          }
        );
        /*
        let features_ats = this.map.queryRenderedFeatures(
          [
            [e.point.x - 0, e.point.y - 0],
            [e.point.x + 0, e.point.y + 0],
          ],
          {
            layers: [
              // "overlay-ats-twp-fill",
              // "overlay-ats-sec-fill",
              // "overlay-ats-qtr-fill",
              "overlay-ats-lsd-fill",
            ],
          }
        );
        */

        this.getElevation(e.lngLat.lng, e.lngLat.lat).then(() => {
          // if (features_ats != "") {
          // let ats_content = "";
          // features_ats.forEach((el) => {
          //   if (el.layer.id === "overlay-ats-twp-fill") {
          //     ats_content = `${el.properties.TWP}-${el.properties.RGE}W${el.properties.M}`;
          //   }
          //   if (el.layer.id === "overlay-ats-sec-fill") {
          //     ats_content = `${el.properties.SEC}-${el.properties.TWP}-${el.properties.RGE}W${el.properties.M}`;
          //   }
          //   if (el.layer.id === "overlay-ats-qtr-fill") {
          //     ats_content = `${el.properties.SEC}-${el.properties.TWP}-${el.properties.RGE}W${el.properties.M}`;
          //   }
          //   if (el.layer.id === "overlay-ats-lsd-fill") {
          //     ats_content = `${el.properties.LS}-${el.properties.SEC}-${el.properties.TWP}-${el.properties.RGE}W${el.properties.M}`;
          //   }
          // });

          let ats_info = this.clickAts
            ? `<b>ATS:</b> ${this.clickAts}<br>`
            : "";
          let elevation_info = this.clickElevation
            ? `<b>Elevation:</b> ${this.clickElevation} m<br><b>`
            : "";
          popupTextAts =
            ats_info +
            elevation_info +
            `GPS:</b> ${e.lngLat.lat.toFixed(5)}&deg;${e.lngLat.lng.toFixed(
              5
            )}&deg;`;

          this.copyToClipboard(this.clickAts);

          // let bbox = extent(features_ats[0]);
          // this.map.fitBounds(bbox, { maxZoom: 15 });
          // }

          // let features_twp = this.map.queryRenderedFeatures(
          //   [
          //     [e.point.x - 0, e.point.y - 0],
          //     [e.point.x + 0, e.point.y + 0],
          //   ],
          //   {
          //     layers: ["overlay-ats-twp-fill"],
          //   }
          // );

          // if (features_twp != "") {
          //   let bbox = extent(features_twp[0]);
          //   this.map.fitBounds(bbox, { maxZoom: 12 });
          // }

          if (features_s1.length !== 0) {
            const pr = ["any"];
            features_s1.forEach((e) => {
              pr.push(["==", "fid", e.properties.fid]);
            });
            this.map.setFilter("overlay-selected-stream_1", pr);
          } else {
            this.map.setFilter("overlay-selected-stream_1", ["in", "fid", ""]);
          }

          if (features_s2.length !== 0) {
            const pr = ["any"];
            features_s2.forEach((e) => {
              pr.push(["==", "fid", e.properties.fid]);
            });
            this.map.setFilter("overlay-selected-stream_2", pr);
          } else {
            this.map.setFilter("overlay-selected-stream_2", ["in", "fid", ""]);
          }

          let fields = ["seedzone", "nsr"];

          if (features_s1 != "") {
            for (let i = 0; i < fields.length; i++) {
              let valueStr = [];
              features_s1.forEach((el) => {
                let value = el.properties[fields[i]];
                value = value ? value : "";
                valueStr.push(value);
              });

              popupText1 += `&nbsp;&nbsp;&nbsp;<strong>${
                fields[i]
              }:</strong> ${valueStr.join(", ")}<br>`;
            }
            popupText1 = `<b>Stream 1:</b><br>${popupText1.replace(
              "nsr:",
              ""
            )}`;
          }

          if (features_s2 != "") {
            for (let i = 0; i < fields.length; i++) {
              let valueStr = [];
              features_s2.forEach((el) => {
                let value = el.properties[fields[i]];
                value = value ? value : "";
                valueStr.push(value);
              });

              popupText2 += `&nbsp;&nbsp;&nbsp;<strong>${
                fields[i]
              }:</strong> ${valueStr.join(", ")}<br>`;
            }
            popupText2 = `<b>Breeding region:</b><br>${popupText2.replace(
              "nsr:",
              ""
            )}`;
          }

          if (features_s1 != "" || features_s2 != "" || popupTextAts != "") {
            popup
              .setLngLat(e.lngLat)
              .setHTML(popupText1 + popupText2 + popupTextAts)
              .addTo(this.map);
          } else {
            popup.remove();
          }
        });
      });

      let query = this.$route.query.query;

      if (query) {
        query = query.split(",");
        query = query
          .filter((item) => {
            return item != "";
          })
          .join(",");
        this.searchAts = query.replaceAll(",", "\n");
        this.getAts(query);
      }

      if (!this.isMobile && this.$localStorage.get("desktopTour") == null) {
        this.$tours["desktopTour"].start();
      }

      if (!this.isMobile) {
        this.initDrawControl(map);
      }

      this.activeBaseLayer = this.$localStorage.get("activeBaseLayer")
        ? this.$localStorage.get("activeBaseLayer")
        : "satellite-google-satellite";

      this.townshipRangeLayer =
        this.$localStorage.get("townshipRangeLayer") &&
        this.$localStorage.get("townshipRangeLayer") === "true"
          ? true
          : false;

      if (!this.$localStorage.get("townshipRangeLayer")) {
        this.townshipRangeLayer = false;
      }

      if (this.$localStorage.get("activeOverlayLayers")) {
        this.activeOverlayLayers = JSON.parse(
          this.$localStorage.get("activeOverlayLayers")
        );
      }
    });
  },
};
</script>
