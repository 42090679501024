import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueMeta);

import Map from "@/components/MainMap.vue";
import Logs from "@/components/Logs.vue";

Vue.use(VueRouter);

let router = new VueRouter({
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  mode: "history",
  routes: [
    {
      path: "/",
      name: "map",
      component: Map,
    },
    {
      path: "/log",
      name: "log",
      component: Logs,
    },
    {
      path: "*",
      component: () =>
        import(/* webpackChunkName: "not-found" */ "@/components/NotFound.vue"),
    },
  ],
});

import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "UA-32681220-2",
  router,
});

export default router;
