<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date range"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range scrollable flat>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(dates);
                  filterByDates();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-text-field
            class="pt-0 mt-0 mb-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-layout style="height: 95vh">
        <v-flex style="overflow: auto">
          <v-data-table
            fixed-header
            :headers="headers"
            :items="log_results_filtered"
            dense
            :loading="tableLoading"
            :sort-by="['insert_time']"
            :sort-desc="[true]"
            :items-per-page="15"
            :search="search"
          >
            <template v-slot:[`item.insert_time`]="{ item }">
              <span>{{ item.insert_time | formatDate }}</span>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  metaInfo: {
    title: "Shakti Tree Map - Logs",
  },

  data() {
    return {
      tableLoading: true,
      headers: [
        { text: "Search Type", value: "search_type" },
        { text: "ATS/Coords", value: "search_txt" },
        { text: "ATS", value: "ats_txt" },
        { text: "IP", value: "ip" },
        { text: "Time", value: "insert_time" },
      ],
      log_results: [],
      log_results_filtered: [],

      dates: ["2020-08-01", new Date().toISOString().substr(0, 10)],
      modal: false,

      start_date_timestamp: 1596229200,
      end_date_timestamp: new Date().getTime() / 1000 + 24 * 60 * 60,

      search: "",
    };
  },

  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  filters: {
    formatDate: (value) => {
      let date = new Date(value * 1000);
      return `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()} ${date.getHours()}:${
        (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
      }`; // timestamp -> DD/MM/YYYY HH:MM
    },
  },

  methods: {
    async getLogs() {
      await axios({
        url: `${process.env.VUE_APP_API_HOST_GET_LOG}`,
        method: "get",
      })
        .then(
          (response) => {
            response = response.data;
            this.log_results = response;
            this.updateTable();
            this.tableLoading = false;
          },
          (error) => {
            console.log("error", error);
            this.loading = false;
            throw error;
          }
        )
        .catch((error) => {
          console.log("cerror", error);
          this.loading = false;
          throw error;
        });
    },

    getTimestamp(date) {
      let date_split = date.split("-");
      const date_new = new Date(
        date_split[0],
        date_split[1] - 1,
        date_split[2]
      );
      return date_new.getTime() / 1000;
    },

    filterByDates() {
      let start_date = this.dates[0];
      let end_date = this.dates[1];

      this.start_date_timestamp = this.getTimestamp(start_date);
      this.end_date_timestamp = this.getTimestamp(end_date);

      if (this.start_date_timestamp > this.end_date_timestamp) {
        this.dates.reverse();
        this.filterByDates();
      }

      this.$router
        .push({
          path: "/log",
          query: {
            from: `${this.dates[0]}`,
            to: `${this.dates[1]}`,
          },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });

      this.updateTable();
    },

    updateTable() {
      this.log_results_filtered = this.log_results.filter((x) => {
        const insert_time = parseInt(x.insert_time);
        return (
          insert_time >= this.start_date_timestamp &&
          insert_time <= this.end_date_timestamp + 24 * 60 * 60
        );
      });
    },
  },

  mounted() {
    this.getLogs().then(() => {
      let from = this.$route.query.from;
      let to = this.$route.query.to;

      if (from && to) {
        this.dates = [from, to];
        this.start_date_timestamp = this.getTimestamp(from);
        this.end_date_timestamp = this.getTimestamp(to);
        this.updateTable();
      }
    });
  },
};
</script>
