import "babel-polyfill";
import Vue from "vue";

import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";

import JsonCSV from "vue-json-csv";
Vue.component("downloadCsv", JsonCSV);

import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  language: "en",
});

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

import store from "@/store";
import router from "@/router/index";

import App from "./App.vue";
import "./registerServiceWorker";

import VueTour from "vue-tour";
Vue.use(VueTour);

import VueLocalStorage from "vue-localstorage";
Vue.use(VueLocalStorage);

Vue.config.productionTip = false;

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  enabled: true,
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    params: {
      send_page_view: true,
    },
  },
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
