import Vue from "vue";

import "@mdi/font/css/materialdesignicons.css";
import Vuetify, { VTextField } from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VTextField,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
});
