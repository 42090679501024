<template>
  <v-dialog v-model="userGuideDialog" persistent max-width="600px">
    <v-stepper v-model="selectedStep" vertical>
      <v-stepper-step :complete="selectedStep > 1" step="1">
        Show or hide right sidebar panel
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-img
          height="250px"
          class="mb-2"
          src="https://picsum.photos/250/100?random"
        ></v-img>
        <small
          >More details details details details details details details details
          details details details details details details details</small
        ><br />
        <v-btn color="primary" @click="selectedStep = 2"> Next </v-btn>
        <v-btn text @click="userGuideDialogClose"> Skip </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="selectedStep > 2" step="2">
        Search by address
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-img
          height="250px"
          class="mb-2"
          src="https://picsum.photos/250/100?random"
        ></v-img>
        <small
          >More details details details details details details details details
          details details details details details details details</small
        ><br />
        <v-btn color="primary" @click="selectedStep = 3"> Next </v-btn>
        <v-btn text @click="userGuideDialogClose"> Skip </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="selectedStep > 3" step="3">
        Change overlay map layer
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-img
          height="250px"
          class="mb-2"
          src="https://picsum.photos/250/100?random"
        ></v-img>
        <small
          >More details details details details details details details details
          details details details details details details details</small
        ><br />
        <v-btn color="primary" @click="selectedStep = 4"> Next </v-btn>
        <v-btn text @click="userGuideDialogClose"> Skip </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="selectedStep > 4" step="4">
        Change base map layer
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-img
          height="250px"
          class="mb-2"
          src="https://picsum.photos/250/100?random"
        ></v-img>
        <small
          >More details details details details details details details details
          details details details details details details details</small
        ><br />
        <v-btn color="primary" @click="selectedStep = 5"> Next </v-btn>
        <v-btn text @click="userGuideDialogClose"> Skip </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="selectedStep > 5" step="5">
        Search by Alberta Township - Range
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-img
          height="250px"
          class="mb-2"
          src="https://picsum.photos/250/100?random"
        ></v-img>
        <small
          >More details details details details details details details details
          details details details details details details details</small
        ><br />
        <v-btn color="primary" @click="selectedStep = 6"> Next </v-btn>
        <v-btn text @click="userGuideDialogClose"> Skip </v-btn>
      </v-stepper-content>

      <v-stepper-step step="6">Measure distances on the map</v-stepper-step>
      <v-stepper-content step="6">
        <v-img
          height="250px"
          class="mb-2"
          src="https://picsum.photos/250/100?random"
        ></v-img>
        <small
          >More details details details details details details details details
          details details details details details details details</small
        ><br />
        <v-btn color="primary" @click="userGuideDialogClose"> Finish </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    userGuideDialog: false,
    selectedStep: 1,
  }),

  methods: {
    userGuideDialogClose() {
      this.userGuideDialog = false;
    },
  },
};
</script>
